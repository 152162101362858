import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Links() {
  return (
    <Layout title="Social Links">
      <SEO title="Creative Box Quick Links" />
      <div className="one-col">
        <a href="/" className="button">
          Website
        </a>
        <a href="/book/" className="button">
          Book Your Session
        </a>
        <a href="/work/" className="button">
          Past Work
        </a>
      </div>
    </Layout>
  )
}
